import React, { useContext } from "react";
import NavMenu from "../../components/nav-menu/NavMenu";
import Helmet from "react-helmet";
import wko from "../../images/wko.png";
import Footer from "../../components/main-page/sections/footer/Footer";
import GroupLegal from "../../components/group-legal/GroupLegal";
import styles from "./index.module.scss";
import { I18nextProvider } from "react-i18next";
import i18n from "../../i18n/i18n";
import DomainContext from "../../context/DomainContext";
import { StaticImage } from "gatsby-plugin-image";
import SEOComponent from "../../components/Shared/Seo";

const labels = [
  {
    subHeading: "Unternehmensdaten",
    para: (
      <>
        <p>
          Service-Region: Mit unserem Partnernetz bedienen wir Sie in ganz
          Österreich und Deutschland
          <br />
          Firmenname: Schrott24 GmbH
          <br />
          Hauptsitz/Adresse:Puchstraße 17, 8020 Graz, Österreich
          <br />
          Firmenbuchnummer: 460642s
          <br />
          Firmengericht: Landesgericht für ZRS Graz
          <br />
          Behörde (gem. ECG): Magistrat der Stadt Graz
          <br />
          Weitere Aufsichtsbehörde (gem. ECG): Amt der Steiermärkischen
          Landesregierung
          <br />
          Rechtsform: Gesellschaft mit beschränkter Haftung
          <br />
          UID-Nummer (Österreich): ATU71533646
          <br />
          UID-Nummer (Deutschland) : DE309103395
          <br />
          Standort-GLN: 9008391634980
          <br />
          Personen-GLN: 9110024000427
          <br />
          Gewerberechtliche Vorschriften: Österreichische Gewerbeordnung (GewO),{" "}
          <a href="http://www.ris.bka.gv.at/" target="_blank" rel="noreferrer">
            http://www.ris.bka.gv.at/
          </a>
          <br />
          <br />
          Unternehmensgegenstand: An- und Verkauf sowie Aufbereitung von Eisen-
          und Stahlschrott, legiertem Schrott, Gußbruch und Buntmetallen.
        </p>
      </>
    ),
  },
  {
    subHeading: "Offenlegung nach Mediengesetz",
    para: (
      <p>
        Firmensitz: Graz
        <br />
        Geschäftsführer: Jan Pannenbäcker BSc, Mag. Alexander Schlick Erklärung
        der grundlegenden Richtung des Mediums: Diese Website stellt
        Informationen zu Produkten und Leistungen unseres Unternehmens dar.
      </p>
    ),
  },
  {
    subHeading: "Mitgliedschaft in der Wirtschaftskammer",
    para: (
      <p>
        Fachgruppe / Berufszweig
        <br />
        LG Maschinen- und Technologiehandel – Handel mit Sekundärrohstoffen
        <img src={wko} alt="wko" className={styles.image} />
      </p>
    ),
  },
  {
    subHeading: "Kontakt",
    para: (
      <>
        <p>
          Mail: <a href="mailto:office@schrott24.at">office@schrott24.at</a>
          <br />
          Link zur europäischen „Online Streitbeilegungsplattform“:{" "}
          <a href="http://ec.europa.eu/odr" target="_blank" rel="noreferrer">
            http://ec.europa.eu/odr
          </a>
        </p>
      </>
    ),
  },
];

const ImpressumPage = () => {
  const domain = useContext(DomainContext);

  return (
    <I18nextProvider i18n={i18n} defaultNS={"default"}>
      <SEOComponent page="impressum" />

      <NavMenu />
      <div className={styles.titleSection}>
        <div className={styles.wContainer}>
          <h1 className={styles.heading1}>Impressum</h1>
          <h2 className={styles.heading2}>Transparenz ist uns wichtig!</h2>
        </div>
      </div>
      <div className={styles.defaultContainer}>
        <div>
          {labels.map(item => (
            <>
              <h2 className={styles.subHeading}>{item.subHeading}</h2>
              <p className={styles.para}>{item.para}</p>
            </>
          ))}
        </div>
      </div>
      <Footer domain={domain} />
      <GroupLegal />
    </I18nextProvider>
  );
};

export default ImpressumPage;
